<template>
  <div class="all">
    <img src="../assets/logo.png" alt="" class="img mr-5" @click="returnToLogin" />
     
    <div class="box">

        <p class="title">Slack Application</p>
              <br> <br>
       <b-alert :show="message === ''" variant="success"
          >Thanks for installing the Altosio Slack Application in your Slack Workspace. You may now close this browser tab and continue the configuration of your connector.</b-alert
        >
        <b-alert :show="message!==''" variant="danger"
          >{{message}}</b-alert
        >
      
     
    </div>
    <p class="address"> © {{year}} Altosio Software, Atelierstraße, 81671 Munich, Germany</p>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "ConsentSlackApp",
  data() {
    return {
      message: '',
        year: new Date().getFullYear(), 
    };
  },
 mounted() {
    this.saveSlackCode();
    this.getMessage()
  },
  methods:{
    getMessage(){
      if(this.$route.query.error!==undefined){
        this.message = "Unable to consent Altosio Slack Application. Error: " + this.$route.query.error;
      }
    },
     returnToLogin(){
           this.$router.push({ path: "/" });
    },
    getSlackCode(){
      var url = window.location.href;
      var indexOfCode = url.indexOf("code=");
      var indexOfState = url.indexOf("state");
      var code = url.substring(indexOfCode+5, indexOfState);
      return code;
    },
    saveSlackCode(){
      var code = this.getSlackCode();
      api.saveSlackCode(code);
    }
  }
};
</script>
<style scoped>
.all {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #f2f6ff;
  text-align: center;
}
.img {
  margin: auto;
  width: 253px;
  height: 75px;
  margin-top: 80px;
  cursor: pointer;
}
.box {
  margin: auto;
  width: 600px;
  height: 400px;
  margin-top: 35px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 4px 4px 15px rgba(225, 225, 225, 0.5);
}
.title {
  margin: auto;
  margin-top: 50px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #23438e;
}
.address {
font-family: Roboto-Regular,Helvetica,Arial,sans-serif;
color: rgba(0,0,0,0.54);
font-size: 11px;
line-height: 18px;
padding-top: 12px;
text-align: center;
}
.rowmargin{
  margin-left: 350px;
}
.hint {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #859ec0;
}
.shape {
  margin-left: 55px;
  width: 472px;
  height: 56px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-repeat: no-repeat;
  text-indent: 40px;
}
.profile {
  background-image: url("../assets/profile.png");
  background-position: 15px 15px;
}

.email {
  background-image: url("../assets/Vector.png");
  background-position: 15px 18px;
}
.password {
  background-image: url("../assets/lock.png");
  background-position: 15px 16px;
}
.company {
  background-image: url("../assets/company.png");
  background-position: 15px 16px;
}
.country {
  background-image: url("../assets/country.png");
  background-position: 15px 16px;
}
.botton {
  margin: auto;
  margin-top: 50px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.reset {
  margin-right: 75px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #23438e;
  cursor: pointer;
}
.signin {
  font-weight: 600;
  color: #23438e;
  cursor: pointer;
}
</style>
